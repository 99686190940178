<template>
  <b-row v-if="$can('read', 'Analytics.Channel')">
    <b-col cols="12">
      <channel-analytics-filters @search="search" />
    </b-col>
    <!-- <b-col cols="12">
      <channel-analytics-of-transactions :data="transactions" />
    </b-col> -->
    <b-col cols="12">
      <channel-analytics-detail :data="channels" />
    </b-col>

    <b-overlay
      :show="loading"
      no-wrap
    />
  </b-row>
</template>

<script>
import {
  BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import { showToast } from '@/libs/tool'

import ChannelAnalyticsFilters from './ChannelAnalyticsFilters.vue'
// import ChannelAnalyticsOfTransactions from './ChannelAnalyticsOfTransactions.vue'
import ChannelAnalyticsDetail from './ChannelAnalyticsDetail.vue'

import useDashboardJs from './dashboard'

const {
  fetchChannelAnalytics,
} = useDashboardJs()

export default {
  components: {
    BRow,
    BCol,
    BOverlay,

    ChannelAnalyticsFilters,
    // ChannelAnalyticsOfTransactions,
    ChannelAnalyticsDetail,
  },
  data() {
    return {
      loading: false,
      transactions: null,
      channels: null,
    }
  },
  methods: {
    search(params) {
      this.transactions = null

      this.loading = true
      fetchChannelAnalytics(
        params,
        data => {
          this.transactions = data.summary
          this.channels = data.channel
          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `fetch daily data fail with ${fail}.`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss">
.chart {
  height: 400px;
}
</style>
