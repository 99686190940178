import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // 获取Merchant Top数据
    fetchMerchantDoughnutAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/merchants-ranking', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchMerchantTopLineAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/merchants-top', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchMerchantTopPaymentAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/payment/merchants-ranking', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取Merchant APP Top数据
    fetchMerchantAppDoughnutAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/merchant-apps-ranking', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchMerchantAppTopLineAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/apps-top', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchMerchantAppTopPaymentAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/payment/merchant-apps-ranking', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取Daily数据
    fetchDailyAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/daily', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取Daily数据
    fetchDailyPaymentAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/payment/daily', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取MOnthly数据
    fetchMonthlyAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/monthly', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchMonthlyPaymentAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/payment/monthly', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 下载分析报告
    downloadAnalyticsReports(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/download', params, { responseType: 'blob' })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取Channel数据
    fetchChannelAnalyticsData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/analytics/channel', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // End
  },
}
