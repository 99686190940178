<template>
  <b-row>
    <b-col v-if="showTable" cols="12">
      <b-card>
        <div class="d-sm-flex justify-content-between align-items-center mb-1">
          <h4 class="card-title mb-50 mb-sm-0">
            Channel Detail
          </h4>
        </div>

        <div class="mb-1">
          <b-row>
            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block"
                  placeholder="Search..."
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          sticky-header="30rem"
          :no-border-collapse="true"
          :bordered="true"
          responsive
          selectable
          select-mode="single"
          :items="tableChannels"
          :fields="tableFields"
          :filter="filter"
          :filter-included-fields="filterOn"
          @row-selected="onRowSelected"
        >
          <template #thead-top>
            <b-tr>
              <b-th />
              <b-th v-for="date in dates" :key="date" colspan="3">
                {{ date }}
              </b-th>
              <b-th>TOTAL</b-th>
              <b-th>PROCESSING</b-th>
              <b-th>SUCCESS</b-th>
              <b-th>REFUSE</b-th>
            </b-tr>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <b-col v-if="showGraph" cols="12">
      <b-card>
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Detail Trends
          </h4>
        </div>

        <!-- chart -->
        <e-charts
          ref="channelDetailChart"
          class="chart"
          :option="channelChartOption"
        />

        <!-- chart -->
        <e-charts
          ref="channelAmountChart"
          class="chart"
          :option="channelAmountChartOption"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BTr, BTh, BFormInput,
} from 'bootstrap-vue'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import { CanvasRenderer } from 'echarts/renderers'
import currency from 'currency.js'
import { BarChart, LineChart } from 'echarts/charts'
import {
  GridComponent, TooltipComponent, ToolboxComponent, LegendComponent, TitleComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  TitleComponent,
])

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTh,
    BFormInput,

    ECharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showTable: false,
      showGraph: false,
      tableFields: ['channel'],
      tableChannels: [],
      filter: null,
      filterOn: [],
      channels: {},
      dates: {},
      totalByCurrency: [],
      datesSummaryList: [],
      channelChartOption: {
        legend: {
          selector: [
            {
              type: 'all or inverse',
              // 可以是任意你喜欢的 title
              title: 'Quick',
            },
          ],
        },
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true,
              },
            },
          },
        },
        xAxis: {
          type: 'category',
          axisLabel: { show: false },
          splitLine: { show: false },
          data: [],
        },
        yAxis: [
          {
            type: 'value',
            name: 'Count',
            splitLine: { show: false },
            axisLabel: {
              formatter: function km(value) {
                if (value > 1000000) {
                  return `${value / 1000000}m`
                }
                if (value > 1000) {
                  return `${value / 1000}k`
                }
                return value
              },
            },
          },
          {
            type: 'value',
            name: 'Rate',
            splitLine: { show: false },
            axisLabel: {
              formatter: function rate(value) {
                return `${value} %`
              },
            },
          },
        ],
        series: [],
      },
      channelAmountChartOption: {
        legend: {
          selector: [
            {
              type: 'all or inverse',
              // 可以是任意你喜欢的 title
              title: 'Quick',
            },
          ],
        },
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true,
              },
            },
          },
        },
        xAxis: {
          type: 'category',
          axisLabel: { show: false },
          splitLine: { show: false },
          data: [],
        },
        yAxis: [
          {
            type: 'value',
            name: 'Amount',
            splitLine: { show: false },
            axisLabel: {
              formatter: function km(value) {
                if (value > 1000000) {
                  return `${value / 1000000}m`
                }
                if (value > 1000) {
                  return `${value / 1000}k`
                }
                return value
              },
            },
          },
        ],
        series: [],
      },
    }
  },
  watch: {
    data: {
      handler(newData) {
        if (!newData) {
          return
        }
        const { channels, dates, detail } = newData
        if (Object.keys(channels).length === 1) {
          Object.keys(channels).forEach(cid => {
            this.drawGraph(cid, dates, detail)
          })
        } else {
          this.drawTable(channels, dates, detail)
        }
      },
      deep: true,
    },
  },
  methods: {
    drawTable(channels, dateList, detail) {
      this.showTable = true

      const dates = []
      dateList.forEach(item => {
        dates.push(item)
      })
      dates.sort()
      this.dates = dates

      const field = []
      field.push({
        key: 'channel',
        variant: 'primary',
        stickyColumn: true,
        isRowHeader: true,
      })
      dates.forEach(date => {
        field.push({ key: `${date}_processing`, label: 'processing', variant: 'secondary' })
        field.push({ key: `${date}_success`, label: 'success', variant: 'success' })
        field.push({ key: `${date}_refuse`, label: 'refuse', variant: 'warning' })
      })
      field.push('total')
      field.push('processing')
      field.push('success')
      field.push({ key: 'refuse', variant: 'danger' })

      this.tableFields = field

      const items = []
      Object.keys(detail).forEach(cid => {
        const item = {
          cid,
          channel: channels[cid],
        }
        dates.forEach(date => {
          if (typeof detail[cid][date] === 'undefined') {
            item[`${date}_processing`] = 0
            item[`${date}_success`] = 0
            item[`${date}_refuse`] = 0
          } else {
            const processingRate = currency((detail[cid][date].processing_cnt / detail[cid][date].total_cnt) * 100)
            const successRate = currency((detail[cid][date].success_cnt / detail[cid][date].total_cnt) * 100)
            const refuseRate = currency((detail[cid][date].refuse_cnt / detail[cid][date].total_cnt) * 100)
            item[`${date}_processing`] = `${processingRate}%`
            item[`${date}_success`] = `${successRate}%`
            item[`${date}_refuse`] = `${refuseRate}%`
          }
        })
        item.total = detail[cid].total_cnt
        item.processing = detail[cid].processing_cnt
        item.success = detail[cid].success_cnt
        item.refuse = detail[cid].refuse_cnt
        items.push(item)
      })
      this.tableChannels = items
    },
    onRowSelected(item) {
      this.drawGraph(item[0].cid, this.data.dates, this.data.detail)
    },
    drawGraph(channelId, dateList, detail) {
      this.showGraph = true
      const localDates = []
      dateList.forEach(item => {
        localDates.push(item)
      })
      localDates.sort()

      const channelChartOptionSeries = []
      const totalCnt = []
      const processingCnt = []
      const successCnt = []
      const refuseCnt = []
      const processingRate = []
      const successRate = []
      const refuseRate = []
      const channelAmountChartOptionSeries = []
      const incomes = []
      const outlays = []

      localDates.forEach(day => {
        if (typeof detail[channelId][day] === 'undefined') {
          totalCnt.push(0)
          processingCnt.push(0)
          successCnt.push(0)
          refuseCnt.push(0)
          processingRate.push(0)
          successRate.push(0)
          refuseRate.push(0)
          incomes.push(0)
          outlays.push(0)
        } else {
          totalCnt.push(detail[channelId][day].total_cnt)
          processingCnt.push(detail[channelId][day].processing_cnt)
          successCnt.push(detail[channelId][day].success_cnt)
          refuseCnt.push(detail[channelId][day].refuse_cnt)
          processingRate.push(currency((detail[channelId][day].processing_cnt / detail[channelId][day].total_cnt) * 100))
          successRate.push(currency((detail[channelId][day].success_cnt / detail[channelId][day].total_cnt) * 100))
          refuseRate.push(currency((detail[channelId][day].refuse_cnt / detail[channelId][day].total_cnt) * 100))
          incomes.push(detail[channelId][day].Income)
          outlays.push(detail[channelId][day].Outlay)
        }
      })

      const totalTrend = {
        name: 'Total',
        type: 'bar',
        data: totalCnt,
      }
      channelChartOptionSeries.push(totalTrend)
      const processingTrend = {
        name: 'Processing',
        type: 'bar',
        data: processingCnt,
      }
      channelChartOptionSeries.push(processingTrend)
      const successTrend = {
        name: 'Success',
        type: 'bar',
        data: successCnt,
      }
      channelChartOptionSeries.push(successTrend)
      const refuseTrend = {
        name: 'Refuse',
        type: 'bar',
        data: refuseCnt,
      }
      channelChartOptionSeries.push(refuseTrend)
      const processingRateTrend = {
        name: 'Processing Rate',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function a(value) {
            return `${value}%`
          },
        },
        data: processingRate,
      }
      channelChartOptionSeries.push(processingRateTrend)
      const successRateTrend = {
        name: 'Success Rate',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function a(value) {
            return `${value}%`
          },
        },
        data: successRate,
      }
      channelChartOptionSeries.push(successRateTrend)
      const refuseRateTrend = {
        name: 'Refuse Rate',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function a(value) {
            return `${value}%`
          },
        },
        data: refuseRate,
      }
      channelChartOptionSeries.push(refuseRateTrend)

      const incomesTrend = {
        name: 'Income',
        type: 'bar',
        data: incomes,
      }
      channelAmountChartOptionSeries.push(incomesTrend)
      const outlaysTrend = {
        name: 'Outlay',
        type: 'bar',
        data: outlays,
      }
      channelAmountChartOptionSeries.push(outlaysTrend)

      setTimeout(() => {
        this.channelChartOption.xAxis.data = localDates
        this.channelChartOption.series = channelChartOptionSeries
        this.$refs.channelDetailChart.setOption(this.channelChartOption)

        this.channelAmountChartOption.xAxis.data = localDates
        this.channelAmountChartOption.series = channelAmountChartOptionSeries
        this.$refs.channelAmountChart.setOption(this.channelAmountChartOption)
      }, 500)
    },
  },
}
</script>
