import store from '@/store'
import dashboardStoreModule from '@/pagsmile/dashboardStoreModule'
import merchantStoreModule from '@/pagsmile/merchantStoreModule'
import channelStoreModule from '@/pagsmile/channelStoreModule'

export default function dashboardJs() {
  const DASHBOARD_STORE_MODULE_NAME = 'dashboard'
  const MERCHANT_STORE_MODULE_NAME = 'merchant'
  const CHANNEL_STORE_MODULE_NAME = 'channel'

  // Register module
  if (!store.hasModule(DASHBOARD_STORE_MODULE_NAME)) store.registerModule(DASHBOARD_STORE_MODULE_NAME, dashboardStoreModule)
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)
  if (!store.hasModule(CHANNEL_STORE_MODULE_NAME)) store.registerModule(CHANNEL_STORE_MODULE_NAME, channelStoreModule)

  const fetchMerchantDoughnut = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchMerchantDoughnutAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }
  const fetchMerchantTopLine = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchMerchantTopLineAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }
  const fetchPaymentMerchantTop = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchMerchantTopPaymentAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }
  const fetchMerchantAppDoughnut = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchMerchantAppDoughnutAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }
  const fetchMerchantAppTopLine = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchMerchantAppTopLineAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }
  const fetchPaymentMerchantAppTop = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchMerchantAppTopPaymentAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchDaily = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchDailyAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }
  const fetchPaymentDaily = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchDailyPaymentAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMonthly = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchMonthlyAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }
  const fetchPaymentMonthly = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchMonthlyPaymentAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }
  const downloadAnalyticsReports = (params, success, fail) => {
    store
      .dispatch('dashboard/downloadAnalyticsReports', params)
      .then(response => {
        success(response)
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchChannelAnalytics = (params, success, fail) => {
    store
      .dispatch('dashboard/fetchChannelAnalyticsData', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchants = (success, fail) => {
    store
      .dispatch('merchant/fetchMerchants')
      .then(response => {
        const { code, data } = response.data

        const merchants = [{ label: 'All', value: '' }]
        if (code === '10000') {
          data.forEach(item => {
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })
        }

        success(merchants)
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchantApps = (merchantNo, success, fail) => {
    store
      .dispatch('merchant/fetchMerchantApps', merchantNo)
      .then(response => {
        const { code, data, msg } = response.data
        const apps = []

        if (code === '10000') {
          apps.push({ label: 'All', value: '' })
          data.forEach(item => {
            const app = { label: `${item.app_name}(${item.region})`, value: item.app_id }
            apps.push(app)
          })
          success(apps)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchChannelFilters = (success, fail) => {
    store
      .dispatch('merchant/fetchChannelFilters')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchChannels = (success, fail) => {
    store
      .dispatch('channel/fetchChannels')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  return {
    fetchMerchantDoughnut,
    fetchMerchantAppDoughnut,
    fetchDaily,
    fetchMonthly,
    downloadAnalyticsReports,
    fetchChannelAnalytics,
    fetchMerchants,
    fetchMerchantApps,
    fetchChannelFilters,
    fetchChannels,
    fetchPaymentDaily,
    fetchPaymentMonthly,
    fetchPaymentMerchantTop,
    fetchPaymentMerchantAppTop,
    fetchMerchantAppTopLine,
    fetchMerchantTopLine,
  }
}
